// https://github.com/uxsolutions/bootstrap-datepicker
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap-datepicker/js/bootstrap-datepicker';
import "../styles/custom-datepicker.css";
// this loads jquery, but does *not* set a global $ or jQuery variable
const $ = require('jquery');
const jQuery = $;
import {TempusDominus, loadLocale, locale} from '@eonasdan/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css';

let plateInput = document.querySelector('#parking_long_ticket_registrationNumber') || document.querySelector('#parking_residents_registrationNumber');

let plates = [];
if (plateInput) {
    document.getElementById('modalspz')
        .querySelectorAll('.d-grid.gap-2 button')
        .forEach((button) => {
            button.addEventListener('click', () => {
                plateInput.value = button.dataset.plate;
                // plateModal.hide();
                //plateInput.dispatchEvent(new Event('change'));
                plateInput.dispatchEvent(new Event('change', {bubbles: true}));
            });

            plates.push({value: button.innerHTML, text: button.innerHTML})
        });
}

;(function ($) {
    $.fn.datepicker.dates['cs'] = {
        days: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
        daysShort: ["Ned", "Pon", "Úte", "Stř", "Čtv", "Pát", "Sob"],
        daysMin: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
        months: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
        monthsShort: ["Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čnc", "Srp", "Zář", "Říj", "Lis", "Pro"],
        today: "Dnes",
        clear: "Vymazat",
        monthsTitle: "Měsíc",
        weekStart: 1,
        format: "dd.mm.yyyy"
    };
}(jQuery));

let init = false;
let timepickers = {};
function initDatepickers(reinit) {

    let dateselect = $('.dateselect');
    if (dateselect.attr('type') === undefined) {
        return;
    }

    if (!init) {
        init = true;
        dateselect.each((key, inp) => {
            let el = inp.closest('div.input-group').querySelector('input.timeselect');
            if (el) {
                let timePicker = new TempusDominus(el, {
                    //put your config here
                    localization: {
                        locale: 'cs',
                        hourCycle: 'h23',
                        format: 'HH:mm',
                    },
                    // useCurrent: true,
                    display: {
                        icons: {
                            type: 'icons',
                            time: 'fe bi-clock',
                            date: 'fe bi-calendar-week',
                            up: 'fe fe-arrow-up',
                            down: 'fe fe-arrow-down',
                            previous: 'fe fe-chevron-left',
                            next: 'fe fe-chevron-right',
                            today: 'fe fe-calendar-check',
                            clear: 'fe fe-trash',
                            close: 'fe fe-x',
                        },
                        theme: 'light',
                        viewMode: 'clock',
                        components: {
                            calendar: false,
                            date: false,
                            month: false,
                            year: false,
                            decades: false,
                            clock: true,
                            hours: true,
                            minutes: false,
                            seconds: false,
                        },
                    },
                });
                timepickers[el.name] = timePicker;
                timePicker.subscribe('change.td', (e) => {
                    if (!e.oldDate) {
                        return;
                    }

                    el.dispatchEvent(new Event('change', {bubbles: true}));
                });
            }
        });
    }

    dateselect.datepicker({
        immediateUpdates: false,
        autoclose: true,
        format: 'yyyy-mm-dd',
        language: 'cs',
        // orientation: 'bottom left', /*bcs of custom css sometimes orientation up si cut off (probably bcs of height computing before custom css load)*/
        // startDate: '-3d'
    }).on('changeDate', function (e) {
        e.stopPropagation();
        e.preventDefault();

        let el = this.closest('div.input-group').querySelector('input.timeselect');
        if (el && !el.value) {
            if (el.name.includes('parkingFrom')) {
                el.value = '00:00';
                el.dispatchEvent(new Event('change', {bubbles: true}));
            } else if (el.name.includes('parkingTo')) {
                el.value = '23:59';
                el.dispatchEvent(new Event('change', {bubbles: true}));
            }
        }

        this.dispatchEvent(new Event('change', {bubbles: true}));
    });

// $('.dateselect2').datepicker({
//     format: 'mm/dd/yyyy',
//     autoclose:true,
//     todayHighlidht: true,
// }).on("hide", function(){
//   if ($)
// }

    $(document).ready(function () {
        $('.showcalendar').click(function (e) {
            e.preventDefault();
            e.stopPropagation();

            $(e.target.closest('.input-group').querySelector('.my-datepicker')).datepicker().focus();
        });
    });

    dateselect.each((key, inp) => {
        let group = inp.closest('div.input-group');
        let el = group.querySelector('input.timeselect');
        if (el) {
            let clockBtn = group.querySelector('.showclock');
            if (clockBtn) {
                clockBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (timepickers[el.name]) {
                        timepickers[el.name].show();
                    }
                });
            }
        }
    });
}

const formPrefix = document.querySelector('.container').dataset.formName || "parking_long_ticket";
const form = document.querySelector('form[name=' + formPrefix + ']');
if (form) {
    form.parentNode.__component.on('render:finished', () => {
        initDatepickers();
    });
}
loadLocale('cs');
locale('cs');
initDatepickers();